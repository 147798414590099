import { ReactNode, useEffect, useState } from "react";
import { ApiClientContext } from "./ApiClientContext";
import { ApiClient } from "@app-stack/api_client";
import Axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";
import { LambdaClient } from "@aws-sdk/client-lambda";

interface ApiClientProviderProps {
  children: ReactNode;
}

export const ApiClientProvider = ({ children }: ApiClientProviderProps) => {
  const [apiClient, setApiClient] = useState<ApiClient>();
  useEffect(() => {
    const initApiClient = async () => {
      const credentials = (await fetchAuthSession()).credentials!;
      const apiClient = new ApiClient(
        process.env.REACT_APP_ENV || "dev",
        "us-east-1",
        Axios.create({
          transformRequest: Axios.defaults.transformRequest,
          transformResponse: Axios.defaults.transformResponse,
        }),
        async () => credentials,
        new LambdaClient({ region: "us-east-1", credentials }),
      );
      setApiClient(apiClient);
    };

    initApiClient();
  }, []);

  return <ApiClientContext.Provider value={apiClient}>{children}</ApiClientContext.Provider>;
};
