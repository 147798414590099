import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);
const baseStyle = definePartsStyle({
    overlay: {
        bg: "blackAlpha.500",
    },
    dialog: {
        borderRadius: "3px",
        minWidth: "700px",
    },
    dialogContainer: {
        width: "100vw",
    },
    transitions: {
        exit: {
            animation: "slideUp 0.1s ease-out forwards",
        },
    },
});
export const modalTheme = defineMultiStyleConfig({
    baseStyle,
});
