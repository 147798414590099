import { useEffect, useRef, useState } from "react";
export function useAnimatedNumber(targetNumber, delay = 10) {
    const localNum = targetNumber !== null && targetNumber !== void 0 ? targetNumber : 0;
    const [displayNumber, setDisplayNumber] = useState(localNum);
    const [step, setStep] = useState(0);
    const displayNumberRef = useRef(displayNumber);
    useEffect(() => {
        displayNumberRef.current = displayNumber;
    }, [displayNumber]);
    useEffect(() => {
        const difference = localNum - displayNumber;
        const newStep = difference / 10;
        if (Math.abs(newStep) > 0.0001) {
            setStep(newStep);
        }
    }, [localNum, displayNumber]);
    useEffect(() => {
        let timeoutId;
        const epsilon = Math.abs(step) / 2;
        const animate = () => {
            if (Math.abs(displayNumber - localNum) < epsilon) {
                setDisplayNumber(localNum);
                return;
            }
            setDisplayNumber((prev) => prev + step);
            timeoutId = setTimeout(animate, delay);
        };
        if (step !== 0) {
            timeoutId = setTimeout(animate, delay);
        }
        return () => clearTimeout(timeoutId);
    }, [localNum, step, delay, displayNumber]);
    return displayNumber;
}
