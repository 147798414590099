import { cardTheme } from "./card";
import { inputTheme } from "./input";
import { selectTheme } from "./select";
import { modalTheme } from "./modal";
import { containerTheme } from "./container";
import { badgeTheme } from "./badge";
import { formErrorTheme } from "./form-error";
export const components = {
    Badge: Object.assign({ baseStyle: {
            borderRadius: "16px",
            padding: "6px 18px 8px",
            fontSize: "1.125rem",
            fontWeight: "800",
        } }, badgeTheme),
    Container: containerTheme,
    Card: cardTheme,
    Input: inputTheme,
    Select: selectTheme,
    Modal: modalTheme,
    FormError: formErrorTheme,
    Button: {
        baseStyle: {
            paddingBottom: "5px",
            borderRadius: "3px",
            margin: "5px",
        },
        variants: {
            ghost: {
                bg: "transparrent",
            },
            outline: {
                bg: "white",
                borderColor: "indigo.500",
                color: "indigo.500",
            },
        },
    },
    Checkbox: {
        baseStyle: {
            control: {
                marginTop: "8px",
            },
            label: {
                fontWeight: "medium",
                color: "gray.900",
                width: "100%",
            },
        },
    },
};
