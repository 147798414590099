import { gql } from "@apollo/client";
export const GET_LAST_QUOTE_CREATION_EVENTS = gql `
  query GetLastQuoteCreationEvents($submissionId: String!) {
    getLastQuoteCreationEvents(submissionId: $submissionId) {
      state
      eventId
      type
    }
  }
`;
export const GET_SUBMISSION_WEB_SEARCH_EVENTS = gql `
  query GetSubmissionWebSearchEvents($submissionId: String!) {
    getSubmissionWebSearchEvents(submissionId: $submissionId) {
      state
      eventId
      type
      payload {
        ... on RedFlagSearchEventPayload {
          name
          state
          id
          submissionId
        }
      }
    }
  }
`;
export const GET_EVENTS = gql `
  query GetEvents($submissionId: String!) {
    getEvents(submissionId: $submissionId) {
      state
      eventId
      type
    }
  }
`;
export const GET_ROSTER_SUBMISSION = gql `
  query GetRosterSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      renewedSubmissionId
      quoteProperties {
        contactEmail
        currentCarrier
        effectiveDate
        expiringPremium
        targetPremium
        renewalPremium
      }
      producer {
        id
        agency {
          name
          agencyId
        }
        producerId
        name
        manager
        managerId
        email
      }
      midlevels {
        id
        firstName
        lastName
        suffix
        specialty
        indigoSpecialty
        retroDate
        limit
        limitType
        address {
          streetName
          secondary
          city
          state
          zip
          countyFips
          countyName
          isSecondary
        }
      }
      providers {
        id
        firstName
        lastName
        suffix
        specialty
        indigoSpecialty
        retroDate
        limit
        limitType
        npi
        nppesCity
        nppesState
        nppesSpecialty
        newToPractice
        partTimePractice
        address {
          streetName
          secondary
          city
          state
          zip
          countyFips
          countyName
          isSecondary
        }
      }
      entities {
        id
        name
        retroDate
        limit
        entityCharges
        npi
        address {
          streetName
          secondary
          city
          state
          zip
          countyFips
          countyName
          isSecondary
        }
      }
    }
  }
`;
export const GET_PREVIOUS_SUBMISSION = gql `
  query GetPreviousSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      isQuickAuditEnabled
      midlevels {
        id
        firstName
        lastName
        previewPremium
      }
      providers {
        id
        previewPremium
        manualPreviewPremium
        npi
        mrs
        manualMrs
        hasEndorsement
      }
      quoteProperties {
        simplifyQuoteId
        expiringPremium
        targetPremium
        renewalPremium
        effectiveDate
        contactEmail
        currentCarrier
        initialPremium
        primaryCustomerId
        isQIP
        policyExclusions
        hasSentDocuments
        emailAddresses {
          to
          cc
          bcc
        }
        modelInfo {
          modelId
          modelVersion
        }
      }
    }
  }
`;
export const GET_SUBMISSION = gql `
  query GetSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      notes
      status
      srfAdjustment
      calculatedSrfAdjustment
      useCalculatedSrfAdjustment
      isQuickAuditEnabled
      policyType
      previewPremium
      previewPremiumsAreStale
      manualPreviewPremiumsAreStale
      manualPreviewPremium
      renewedSubmissionId
      canShowModelInsights
      declination {
        emailAddresses {
          to
          cc
          bcc
        }
        reason
        otherReason
      }
      quoteProperties {
        simplifyQuoteId
        expiringPremium
        targetPremium
        renewalPremium
        effectiveDate
        contactEmail
        currentCarrier
        initialPremium
        primaryCustomerId
        isQIP
        policyExclusions
        hasSentDocuments
        emailAddresses {
          to
          cc
          bcc
        }
        modelInfo {
          modelId
          modelVersion
        }
      }
      producer {
        id
        name
        manager
        managerId
        producerId
        email
        agency {
          name
          agencyId
        }
      }
      entities {
        id
        name
        npi
        retroDate
        limit
        entityCharges
        simplifyCustomerId
        address {
          streetName
          secondary
          city
          state
          zip
          countyFips
          countyName
          isSecondary
        }
      }
      underwriterNotes {
        id
        content
        createdAt
        author
        type
      }
      providers {
        id
        firstName
        lastName
        hasEndorsement
        suffix
        specialty
        npi
        indigoSpecialty
        manualIndigoSpecialty
        mrs
        manualMrs
        newToPractice
        nppesCity
        nppesState
        nppesSpecialty
        deductible
        limitType
        limit
        retroDate
        scheduledRatingFactor
        simplifyCustomerId
        excludedNpi
        ignoreLocationInconsistency
        cyberOptOut
        partTimePractice
        isUserClient
        previewPremium
        manualPreviewPremium
        initialPremium
        redFlagSearchCompleted
        redFlagSearchState
        negativeFactors {
          name
          category
          score
          value
        }
        mrsTrends {
          version
          value
        }
        billingData {
          hvData {
            year
            amount
          }
          payerData {
            year
            amount
          }
        }
        inconsistencies {
          location {
            ds
            iqvia
            inferred
            declared
            isConsistent
          }
          specialty {
            nppes
            dhc
            inferred
            declared
            ds
            isConsistent
          }
        }
        address {
          streetName
          secondary
          city
          state
          zip
          countyFips
          countyName
          isSecondary
        }
        redFlags {
          displayLink
          htmlTitle
          htmlSnippet
          link
          snippet
          title
          redFlagSummary
          query
          date
          isSpecialtySearch
        }
        boardActions {
          npiSource
          termLength
          state
          details
          orderDate
          reportingEntity
          severity
          summary
          npi
        }
        stateLicenses {
          number
          state
        }
        claims {
          id
          npi
          claim
          reason
          severity
          claimState
          dataSource
          indemnityPaid
          incidentDate
          claimUrl
        }
      }
      midlevels {
        id
        firstName
        lastName
        suffix
        specialty
        simplifyCustomerId
        npi
        indigoSpecialty
        limitType
        limit
        retroDate
        partTimePractice
        previewPremium
        initialPremium
        manualPreviewPremium
        address {
          streetName
          secondary
          city
          state
          zip
          countyFips
          countyName
          isSecondary
        }
      }
    }
  }
`;
export const GET_PROVIDERS_RED_FLAGS = gql `
  query GetProvidersRedFlags($submissionId: ID!) {
    getSubmission(id: $submissionId) {
      providers {
        id
        redFlagSearchState
        redFlags {
          displayLink
          htmlTitle
          htmlSnippet
          link
          snippet
          title
          redFlagSummary
          query
          date
        }
      }
    }
  }
`;
export const GET_POLICIES_BY_OFFICE = gql `
  query GetPoliciesByOffice($officeId: ID!) {
    getPoliciesByOffice(officeId: $officeId) {
      id
      name
      policyNumber
      totalPremium
      status
      effectiveDate
      underwriter {
        id
        name
        email
        phone
      }
      bills {
        id
        billNumber
        paymentAmount
        paymentDate
        status
        dueDate
        updatedAt
        createdAt
      }
    }
  }
`;
export const GET_POLICY = gql `
  query GetPolicy($id: ID!) {
    getPolicy(id: $id) {
      id
      name
      policyNumber
      totalPremium
      status
      effectiveDate
      underwriter {
        id
        name
        email
        phone
      }
      bills {
        id
        billNumber
        paymentAmount
        paymentDate
        status
        dueDate
        updatedAt
        createdAt
      }
    }
  }
`;
export const GET_ALL_ORG_POLICIES = gql `
  query GetAllOrgPolicies($orgId: ID!) {
    getAllOrgPolicies(orgId: $orgId) {
      id
      name
      policyNumber
      totalPremium
      status
      effectiveDate
      officeName
      underwriter {
        id
        name
        email
        phone
      }
      bills {
        id
        billNumber
        paymentAmount
        paymentDate
        status
        dueDate
        updatedAt
        createdAt
      }
    }
  }
`;
