import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys);
const outline = definePartsStyle({
    field: {
        borderColor: "indigo.300",
        borderRadius: "2px",
        paddingLeft: "8px",
        paddingRight: "12px",
        _hover: {
            borderColor: "indigo.500",
        },
        _focus: {
            borderColor: "indigo.500",
            boxShadow: "0 0 0 1px #2C0B9B",
        },
    },
});
export const selectTheme = defineMultiStyleConfig({ variants: { outline } });
