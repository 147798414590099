var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useCallback } from "react";
export const useSmartyStreets = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [autocompleteData, setAutocompleteData] = useState();
    const [detailsData, setDetailsData] = useState(null);
    const autocomplete = useCallback((search) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(null);
        const searchEnc = encodeURIComponent(search);
        const key = "170117547993626608";
        const url = `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${key}&search=${searchEnc}`;
        try {
            const response = yield fetch(url, {
                method: "GET",
                headers: {
                    Referer: "localhost",
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not OK");
            }
            const data = yield response.json();
            setAutocompleteData(data);
            return data;
        }
        catch (error) {
            setError(error);
            throw error;
        }
        finally {
            setLoading(false);
        }
    }), []);
    const details = useCallback((street, city, state) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(null);
        const host = "us-street.api.smartystreets.com";
        const key = "170117547993626608";
        const _street = encodeURIComponent(street);
        const _city = encodeURIComponent(city);
        const url = `https://${host}/street-address?key=${key}&license=us-core-cloud&street=${_street}&city=${_city}&state=${state}&candidates=10`;
        try {
            const response = yield fetch(url, {
                method: "GET",
                headers: {
                    Referer: "localhost",
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not OK");
            }
            const data = yield response.json();
            const addressData = data.map((d) => {
                var _a, _b, _c, _d;
                return {
                    street: (_a = d === null || d === void 0 ? void 0 : d.components) === null || _a === void 0 ? void 0 : _a.street_name,
                    countyName: (_b = d === null || d === void 0 ? void 0 : d.metadata) === null || _b === void 0 ? void 0 : _b.county_name,
                    countyFips: (_c = d === null || d === void 0 ? void 0 : d.metadata) === null || _c === void 0 ? void 0 : _c.county_fips,
                    postalCode: (_d = d === null || d === void 0 ? void 0 : d.components) === null || _d === void 0 ? void 0 : _d.zipcode,
                };
            })[0];
            setDetailsData(addressData);
            return addressData;
        }
        catch (error) {
            setError(error);
        }
        finally {
            setLoading(false);
        }
    }), []);
    return {
        loading,
        error,
        autocompleteData,
        detailsData,
        autocomplete,
        details,
    };
};
