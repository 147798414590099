import { useState, useEffect } from "react";
export function useLocalAuthSession() {
    const [user, setUser] = useState(() => {
        try {
            const storedUser = JSON.parse(localStorage.getItem("signedInUser") || "null");
            return storedUser;
        }
        catch (e) {
            console.error("Failed parsing local user: ", e);
            return null;
        }
    });
    useEffect(() => {
        const updateUserFromStorage = () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem("signedInUser") || "null");
                setUser(storedUser);
            }
            catch (e) {
                console.error("Failed parsing local user: ", e);
            }
        };
        const handleStorageChange = (event) => {
            if (event.key === "signedInUser") {
                updateUserFromStorage();
            }
        };
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);
    return user;
}
