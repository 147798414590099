import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { typography } from "./typography";
import { components } from "./components";
import { radii } from "./radii";
import { shadows } from "./shadows";
export const theme = extendTheme(Object.assign(Object.assign({}, typography), { colors,
    components,
    radii,
    shadows, styles: {
        global: {
            "@keyframes slideIn": {
                from: {
                    transform: "translateY(-100%)",
                    opacity: 0,
                },
                to: {
                    transform: "translateY(0)",
                    opacity: 1,
                },
            },
            "@keyframes slideUp": {
                from: {
                    transform: "translateY(0)",
                    opacity: 1,
                },
                to: {
                    transform: "translateY(-100%)",
                    opacity: 0,
                },
            },
        },
    } }));
