import { Flex } from "@chakra-ui/react";
import { GoTriangleDown } from "react-icons/go";

export function SelectArrow() {
  return (
    <Flex
      position="absolute"
      pt="4px"
      pr="5px"
      right="0.15"
      w="50px !important"
      background="linear-gradient(90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,1) 50%)"
      justifyContent="flex-end"
    >
      <GoTriangleDown fontSize="20px" />
    </Flex>
  );
}
