import "reflect-metadata";
import { Observable } from "@apollo/client/core";
import { ApolloLink } from "@apollo/client/link/core";
import { Sha256 } from "@aws-crypto/sha256-js";
import { SignatureV4 } from "@smithy/signature-v4";
import { print } from "graphql/language/printer";
import { BatchLink } from "@apollo/client/link/batch";
export class AwsHttpBatchLink extends ApolloLink {
    constructor(axiosClient, credentialProvider, region, url, batchMax = 10, batchInterval = 20) {
        super();
        this.httpClient = axiosClient;
        this.requestSigner = new SignatureV4({
            credentials: credentialProvider,
            region: region,
            service: "execute-api",
            sha256: Sha256,
        });
        this.region = region;
        this.url = url;
        this.batchMax = batchMax;
        this.batchInterval = batchInterval;
        this.batchLink = new BatchLink({
            batchMax: this.batchMax,
            batchInterval: this.batchInterval,
            batchHandler: this.batchRequest.bind(this),
        });
    }
    request(operation) {
        return this.batchLink.request(operation);
    }
    batchRequest(operations) {
        return new Observable((observer) => {
            const { hostname, pathname } = new URL(this.url);
            const batchedBody = JSON.stringify(operations.map((operation) => ({
                operationName: operation.operationName,
                query: print(operation.query),
                variables: operation.variables,
            })));
            const unsigned = {
                method: "POST",
                protocol: "https",
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json; charset=UTF-8",
                    host: hostname,
                },
                hostname: hostname,
                path: pathname,
                body: batchedBody,
            };
            this.requestSigner
                .sign(unsigned)
                .then((signed) => {
                return this.httpClient.request({
                    method: signed.method,
                    url: this.url,
                    headers: signed.headers,
                    data: signed.body,
                });
            })
                .then((response) => {
                const results = response.data.map((result) => {
                    var _a;
                    return ({
                        data: result.data,
                        errors: (_a = result.errors) !== null && _a !== void 0 ? _a : [],
                    });
                });
                observer.next(results);
                observer.complete();
            })
                .catch((err) => observer.error(err));
        });
    }
}
