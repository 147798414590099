import { Box, Card, CardBody, CardHeader, Text } from "@chakra-ui/react";
import { FormError } from "components";

interface QuoteErrorCardProps {
  sunlightErrors: string[];
}
export function QuoteErrorCard({ sunlightErrors }: QuoteErrorCardProps) {
  return sunlightErrors?.length > 0 ? (
    <Box mb="30px" w="100%">
      <Card>
        <CardHeader>
          <Text>
            The following errors occured during the last time this submission was sent to sunlight:
          </Text>
        </CardHeader>
        <CardBody>
          {sunlightErrors.map((error, index) => (
            <FormError key={index} message={error} />
          ))}
        </CardBody>
      </Card>
    </Box>
  ) : null;
}
