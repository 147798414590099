import { useEffect, useState } from "react";
import { debounce } from "utils";
export const useIntersectionObserver = (ref, scrollLock, delay = 100) => {
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const debouncedSetIsVisible = debounce((value) => {
            setIsVisible(value);
        }, delay);
        const observer = new IntersectionObserver(([entry]) => {
            if (!scrollLock.isScrollingRef.current) {
                debouncedSetIsVisible(entry.isIntersecting);
            }
        }, {
            root: null,
            rootMargin: "-50% 0px",
            threshold: 0,
        });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [ref, delay, scrollLock]);
    return isVisible;
};
