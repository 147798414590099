var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { UPDATE_SUBMISSION } from "mutations";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SUBMISSION } from "queries";
export function useToggleQuickAudit(id) {
    var _a, _b;
    const [updateSubmission] = useMutation(UPDATE_SUBMISSION, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    });
    const { data } = useQuery(GET_SUBMISSION, {
        variables: { id: id },
    });
    return {
        toggleQuickAudit: (isQuickAuditEnabled) => __awaiter(this, void 0, void 0, function* () {
            try {
                if (!id) {
                    throw new Error("Submission ID is required");
                }
                yield updateSubmission({
                    variables: {
                        input: {
                            id,
                            isQuickAuditEnabled,
                        },
                    },
                });
            }
            catch (e) {
                console.error(e);
            }
        }),
        isQuickAuditEnabled: (_b = (_a = data === null || data === void 0 ? void 0 : data.getSubmission) === null || _a === void 0 ? void 0 : _a.isQuickAuditEnabled) !== null && _b !== void 0 ? _b : true,
    };
}
