var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getNppesApiResultItemFirstName, getNppesApiResultItemLastName, } from "@app-stack/types/nppes";
import { getSpecialtyDetails } from "app-constants";
import { ApiClientContext } from "providers";
import { useCallback, useContext, useState } from "react";
export function useNpiSearch() {
    const [isLoading, setIsLoading] = useState(false);
    const [matches, setMatches] = useState(undefined);
    const apiClient = useContext(ApiClientContext);
    const fetchMatches = useCallback((provider) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        setIsLoading(true);
        try {
            const responseData = yield (apiClient === null || apiClient === void 0 ? void 0 : apiClient.npiSearch({
                params: (provider === null || provider === void 0 ? void 0 : provider.npi)
                    ? [
                        {
                            first_name: (provider === null || provider === void 0 ? void 0 : provider.firstName) || "",
                            last_name: (provider === null || provider === void 0 ? void 0 : provider.lastName) || "",
                            taxonomySearches: [],
                            number: provider.npi,
                        },
                    ]
                    : [
                        {
                            first_name: (provider === null || provider === void 0 ? void 0 : provider.firstName) || "",
                            last_name: (provider === null || provider === void 0 ? void 0 : provider.lastName) || "",
                            taxonomySearches: getSpecialtyDetails(provider === null || provider === void 0 ? void 0 : provider.indigoSpecialty).search || [],
                            state: ((_a = provider === null || provider === void 0 ? void 0 : provider.address) === null || _a === void 0 ? void 0 : _a.state) || "",
                            number: (provider === null || provider === void 0 ? void 0 : provider.npi) || "",
                        },
                    ],
                useSpecialtyInLookup: true,
            }));
            const newMatches = ((_b = responseData === null || responseData === void 0 ? void 0 : responseData[0]) === null || _b === void 0 ? void 0 : _b.map((response) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                return ({
                    providerName: `${getNppesApiResultItemFirstName(response)} ${getNppesApiResultItemLastName(response)}`,
                    npi: (response === null || response === void 0 ? void 0 : response.number) || "",
                    address: `${(_b = (_a = response === null || response === void 0 ? void 0 : response.addresses) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.address_1}, ${(_d = (_c = response === null || response === void 0 ? void 0 : response.addresses) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.city}, ${(_f = (_e = response === null || response === void 0 ? void 0 : response.addresses) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.state} ${(_h = (_g = response === null || response === void 0 ? void 0 : response.addresses) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.postal_code}`,
                    city: (_k = (_j = response === null || response === void 0 ? void 0 : response.addresses) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.city,
                    state: (_m = (_l = response === null || response === void 0 ? void 0 : response.addresses) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.state,
                    specialty: ((_p = (_o = response === null || response === void 0 ? void 0 : response.taxonomies.filter((t) => t === null || t === void 0 ? void 0 : t.primary)) === null || _o === void 0 ? void 0 : _o[0]) === null || _p === void 0 ? void 0 : _p.desc) ||
                        "",
                });
            })) || [];
            const uniqueMatches = [];
            const seenNpis = new Set();
            for (const match of newMatches) {
                if (!seenNpis.has(match.npi)) {
                    seenNpis.add(match.npi);
                    uniqueMatches.push(match);
                }
            }
            setMatches(uniqueMatches || []);
            return uniqueMatches || [];
        }
        catch (e) {
            console.error("Error fetching npi data", e);
        }
        finally {
            setIsLoading(false);
        }
    }), [apiClient]);
    return { isLoading, matches, fetchMatches };
}
