export const INDIGO_NON_PHYSICIAN_SPECIALTIES = [
    { name: "Advanced Practice Registered Nurse (APRN)", value: 3183 },
    { name: "Anesthesiology Assistant (AA)", value: 3184 },
    { name: "Audiologist", value: 3185 },
    { name: "Cardiac Technician", value: 3186 },
    { name: "Casting Technician", value: 3187 },
    { name: "Certified Nurse Midwife (CNM)", value: 3188 },
    { name: "Certified Nurse Practitioner", value: 3189 },
    { name: "Chiropractor", value: 3190 },
    { name: "Clinical Nurse Specialist", value: 3191 },
    { name: "Counselor", value: 3192 },
    { name: "CRNA", value: 3193 },
    { name: "Dietitian", value: 3194 },
    { name: "EKG Technician", value: 3195 },
    { name: "Emergency Medical Technician (EMT)", value: 3196 },
    { name: "Medical Assistant", value: 3197 },
    { name: "Medical Lab Technician", value: 3198 },
    { name: "Nurse", value: 3199 },
    { name: "Nurse - Student", value: 3200 },
    { name: "Nurse, RN Perform X-Ray Therapy", value: 3201 },
    { name: "O.R. Technician", value: 3202 },
    { name: "Occupation Therapist", value: 3203 },
    { name: "Ophthalmic Assistant", value: 3204 },
    { name: "Optometrist (Optical) 809", value: 3205 },
    { name: "Optometrist", value: 3205 },
    { name: "Optometrist (Employee)", value: 3205 },
    { name: "Optometrist (Independent)", value: 3205 },
    { name: "Orthopedic Technician", value: 3206 },
    { name: "Perfusionist", value: 3207 },
    { name: "Pharmacist", value: 3208 },
    { name: "Phlebotomist", value: 3209 },
    { name: "Physical Therapist", value: 3210 },
    { name: "Physician Assistant", value: 3211 },
    { name: "Physical Therapist (Emp)", value: 3210 },
    { name: "Physical Therapist (Ind)", value: 3210 },
    { name: "Physiotherapist", value: 3212 },
    { name: "Podiatrist", value: 3213 },
    { name: "Psychologist", value: 3214 },
    { name: "Pump Technician", value: 3215 },
    { name: "Respiratory Therapist", value: 3216 },
    { name: "Social Worker", value: 3217 },
    { name: "Sonographer", value: 3218 },
    { name: "Surgeon Assistant", value: 3219 },
    { name: "X-Ray/Radiologic Technician", value: 3221 },
    { name: "X-Ray Therapy Technician", value: 3220 },
];
export const INDIGO_SPECIALTIES = [
    { name: "Acupuncture", value: 3101, search: ["acu"], nucc_specialties: ["171100000X"] },
    { name: "Aerospace Medicine", value: 3102, search: ["aer"], nucc_specialties: ["2083A0100X"] },
    {
        name: "Allergy",
        value: 3103,
        search: ["all", "oto", "ped", ""],
        nucc_specialties: [
            "207K00000X",
            "2080I0007X",
            "207RA0201X",
            "207KA0200X",
            "207YX0602X",
            "207KI0005X",
            "2080P0201X",
        ],
    },
    {
        name: "Anesthesiology",
        value: 3104,
        search: ["anes"],
        nucc_specialties: ["207L00000X", "207LP3000X", "207LA0401X", "207LH0002X", "207LC0200X"],
    },
    { name: "Bariatric-Surgery", value: 3105 }, // only produced in model_id 64af127a4e00004a0057185a
    {
        name: "Cardiovascular Disease-Minor Surgery",
        value: 3107,
        search: ["ped", "int", "adu", "adv", "clinical card"],
        nucc_specialties: ["2080P0202X", "207RA0002X", "207RC0001X", "207RI0011X", "207RA0001X"],
    },
    {
        name: "Cardiovascular Disease-No Surgery",
        value: 3106,
        search: ["int", "card"],
        nucc_specialties: ["207RH0005X", "207RC0000X"],
    },
    {
        name: "Cardiovascular Disease-Surgery",
        value: 3108,
        search: ["adv"],
        nucc_specialties: ["207UN0901X"],
    },
    { name: "Catheterization NOC", value: 3109 }, // never produced this specialty
    {
        name: "Colon And Rectal-Surgery",
        value: 3110,
        search: ["colon"],
        nucc_specialties: ["208C00000X"],
    },
    { name: "Cosmetic-Surgery", value: 3111 }, // never produced this specialty
    {
        name: "Dermatology-No Surgery",
        value: 3112,
        search: ["derma"],
        nucc_specialties: ["207N00000X", "207NP0225X", "207NI0002X"],
    },
    {
        name: "Dermatology-Minor Surgery",
        value: 3113,
        search: ["derma"],
        nucc_specialties: ["207N00000X", "207ND0101X", "207NS0135X", "207NI0002X", "207NP0225X"],
    },
    {
        name: "Emergency Med-No Surgery",
        value: 3114,
        search: ["emergency medi", "ped", "sports"],
        nucc_specialties: [
            "207PP0204X",
            "207PE0004X",
            "207PS0010X",
            "207PH0002X",
            "207PT0002X",
            "2080P0204X",
            "207P00000X",
            "207PE0005X",
        ],
    },
    {
        name: "Endocrinology-No Surgery",
        value: 3115,
        search: ["endo", "ped"],
        nucc_specialties: ["2080P0205X", "207RE0101X"],
    },
    {
        name: "Family Medicine-Minor Surgery",
        value: 3117,
        search: ["family", "family med", "gen", "hos"],
        nucc_specialties: [
            "207QS0010X",
            "207Q00000X",
            "207QB0002X",
            "207QA0505X",
            "207QH0002X",
            "207QA0000X",
            "207QG0300X",
            "207QS1201X",
            "208D00000X",
            "207QA0401X",
        ],
    },
    {
        name: "Family Medicine-No Surgery",
        value: 3116,
        search: ["family med", "hosp", "sport", "gen"],
        nucc_specialties: [
            "207QS1201X",
            "207QA0000X",
            "207QH0002X",
            "208D00000X",
            "207QA0505X",
            "207Q00000X",
            "207QS0010X",
            "207QG0300X",
            "207QA0401X",
        ],
    },
    { name: "Family Medicine-Surgery", value: 3118 }, // never produced this specialty
    { name: "Family Medicine Incl Deliveries-Surgery", value: 3119 }, // never produced this specialty
    {
        name: "Forensic Medicine",
        value: 3120,
        search: ["leg", "psych", "for", "ind"],
        nucc_specialties: ["2084F0202X", "173000000X", "209800000X", "202C00000X"],
    },
    {
        name: "Gastroenterology-Minor Surgery",
        value: 3122,
        search: ["gastro", "hepatology", "transplant hepatology", "internal", "pediatric gastro"],
        nucc_specialties: ["207RG0100X", "207RT0003X", "2080P0206X", "207RI0008X"],
    },
    {
        name: "Gastroenterology-No Surgery",
        value: 3121,
        search: ["gastro", "pediatric gastro", "hepatology"],
        nucc_specialties: ["2080P0206X", "207RI0008X", "207RG0100X"],
    },
    {
        name: "General NOC-Surgery",
        value: 3123,
        search: [""],
        nucc_specialties: ["204F00000X", "208600000X", "174400000X", "2086H0002X", "2086X0206X"],
    },
    {
        name: "General Preventive Med-No Surgery",
        value: 3124,
        search: ["prev", "spor", "med", "medical gen", "clini"],
        nucc_specialties: [
            "2083T0002X",
            "207SG0202X",
            "207SG0203X",
            "2083P0500X",
            "2083A0300X",
            "2083B0002X",
            "2083S0010X",
            "2083P0011X",
            "207SG0201X",
            "207SG0205X",
            "2083P0901X",
            "2083C0008X",
            "207SC0300X",
        ],
    },
    {
        name: "Geriatrics-No Surgery",
        value: 3125,
        search: ["geria"],
        nucc_specialties: ["207RG0300X"],
    },
    { name: "Geriatrics-Minor Surgery", value: 3126 }, // never produced this specialty
    { name: "Geriatrics-Surgery", value: 3127 }, // never produced this specialty
    {
        name: "Gynecology-Minor Surgery",
        value: 3129,
        search: ["repro"],
        nucc_specialties: ["207VE0102X"],
    },
    {
        name: "Gynecology-No Surgery",
        value: 3128,
        search: ["gyn", "hospice and"],
        nucc_specialties: ["207VG0400X", "207VH0002X"],
    },
    {
        name: "Gynecology-Surgery",
        value: 3130,
        search: ["gyn", "hospice and", "obst", "urol"],
        nucc_specialties: ["207VG0400X", "207VH0002X", "2088F0040X", "207VF0040X", "207VX0201X"],
    },
    {
        name: "Hand-Surgery",
        value: 3131,
        search: ["surge", "ortho"],
        nucc_specialties: ["207XS0106X", "2086S0105X", "2082S0105X"],
    },
    {
        name: "Hematology-No Surgery",
        value: 3132,
        search: ["hema", "pedia", "int"],
        nucc_specialties: ["2080P0207X", "207ZH0000X", "207RH0003X", "207RH0000X", "207RX0202X"],
    },
    { name: "Hospitalists", value: 3133, search: ["hospitalist"], nucc_specialties: ["208M00000X"] },
    { name: "Hypnosis", value: 3134 }, // never produced this specialty
    {
        name: "Infectious Diseases-No Surgery",
        value: 3135,
        search: ["infec", "pediatric"],
        nucc_specialties: ["2080P0208X", "207RI0200X"],
    },
    { name: "Intensive Care Medicine", value: 3136 }, // never produced this specialty
    {
        name: "Internal Medicine-Minor Surgery",
        value: 3138,
        search: ["internal", "ped", "int", "mag", "clinical &", "sleep", "hospice"],
        nucc_specialties: [
            "207R00000X",
            "207RM1200X",
            "207RB0002X",
            "207RC0200X",
            "207RS0012X",
            "207RS0010X",
            "207RA0000X",
            "2080P0203X",
            "207RH0002X",
            "207RA0401X",
            "207RI0001X",
        ],
    },
    {
        name: "Internal Medicine-No Surgery",
        value: 3137,
        search: ["internal", "ped", "mag", "clinical &", "sleep", "hospice"],
        nucc_specialties: [
            "207R00000X",
            "207RA0401X",
            "207RC0200X",
            "207RA0000X",
            "207RS0012X",
            "207RS0010X",
            "207RH0002X",
            "2080P0203X",
            "207RI0001X",
            "207RM1200X",
        ],
    },
    { name: "Neonatology", value: 3139, search: ["neo"], nucc_specialties: ["2080N0001X"] },
    {
        name: "Nephrology-Minor Surgery",
        value: 3141,
        search: ["neu", "epilepsy", "int"],
        nucc_specialties: ["2084P0301X", "2084E0001X"],
    },
    {
        name: "Nephrology-No Surgery",
        value: 3140,
        search: ["pediatric", "neph"],
        nucc_specialties: ["207RN0300X", "2080P0210X"],
    },
    {
        name: "Neurology-No Surgery",
        value: 3142,
        search: [
            "neu",
            "psychiatry",
            "neuro",
            "neurodev",
            "sleep",
            "physical medicine",
            "behavioral",
            "electrodiagnostic",
            "sports medicine",
            "fam",
            "",
        ],
        nucc_specialties: [
            "2084N0402X",
            "2080P0008X",
            "2084A2900X",
            "2084B0040X",
            "2084P0005X",
            "2084S0012X",
            "2080S0012X",
            "2084S0010X",
            "2084N0400X",
            "2084V0102X",
            "204R00000X",
            "2084H0002X",
        ],
    },
    { name: "Neurology-Minor Surgery", value: 3143 }, // only produced in 64cda7894a00004200d60c4c, 64fa3a924f000046002895cd, 64cda7894a00004200d60c4c_Aug16
    {
        name: "Neurology-Surgery",
        value: 3144,
        search: [
            "neu",
            "psychiatry",
            "neuro",
            "neurodev",
            "sleep",
            "physical medicine",
            "behavioral",
            "electrodiagnostic",
            "sports medicine",
            "fam",
            "",
        ],
        nucc_specialties: [
            "2084N0400X",
            "2084N0402X",
            "2084A2900X",
            "2084B0040X",
            "2080P0008X",
            "204R00000X",
            "2084S0010X",
            "207T00000X",
            "2084S0012X",
            "2084H0002X",
            "2080S0012X",
            "2084V0102X",
            "2084P0005X",
        ],
    },
    {
        name: "Nuclear Medicine",
        value: 3145,
        search: ["nuclear"],
        nucc_specialties: ["207UN0902X", "207U00000X", "207UN0903X"],
    },
    {
        name: "Obstetrics Gynecology-Surgery",
        value: 3146,
        search: ["obstetrics &", "obst", "obstetric", "gyn"],
        nucc_specialties: ["207V00000X", "207VX0000X", "207VC0200X", "207VM0101X", "207VB0002X"],
    },
    {
        name: "Occupational Medicine",
        value: 3147,
        search: ["prevent"],
        nucc_specialties: ["2083X0100X"],
    },
    {
        name: "Ophthalmology-Minor Surgery",
        value: 3149,
        search: ["ophthalmology", "retina", "neuro", "psychiatry"],
        nucc_specialties: [
            "207WX0120X",
            "207WX0110X",
            "207WX0107X",
            "207W00000X",
            "207WX0109X",
            "207WX0009X",
            "207WX0108X",
        ],
    },
    {
        name: "Ophthalmology-No Surgery",
        value: 3148,
        search: ["ophthalmology", "neuro", "psychiatry"],
        nucc_specialties: ["207W00000X", "207WX0109X"],
    },
    { name: "Ophthalmology-Surgery", value: 3150 }, // only produced in 64af127a4e00004a0057185a
    {
        name: "Orthopedic Excl Back-Surgery",
        value: 3151,
        search: ["orthopaedic surgery", "podiatrist", ""],
        nucc_specialties: [
            "207XP3100X",
            "2080S0010X",
            "207XX0801X",
            "207XX0004X",
            "207XS0114X",
            "207XX0005X",
            "207X00000X",
        ],
    },
    {
        name: "Orthopedic Incl Back-Surgery",
        value: 3152,
        search: ["orthopaedic surgery", "podiatrist", ""],
        nucc_specialties: [
            "207X00000X",
            "207XS0114X",
            "2080S0010X",
            "207XS0117X",
            "207XP3100X",
            "207XX0005X",
            "207XX0004X",
            "207XX0801X",
        ],
    },
    {
        name: "Otorhinolaryngology-No Surgery",
        value: 3153,
        search: ["oto"],
        nucc_specialties: ["207YP0228X", "207YX0901X"],
    },
    {
        name: "Otorhinolaryngology-Minor Surgery",
        value: 3154,
        search: ["oto"],
        nucc_specialties: ["207YS0012X", "207Y00000X"],
    },
    {
        name: "Otorhinolaryngology-Surgery",
        value: 3155,
        search: ["oto"],
        nucc_specialties: ["207YX0901X", "207YP0228X", "207YX0905X"],
    },
    { name: "Pain Management", value: 3156 }, // never produced in any model
    {
        name: "Pain Medicine",
        value: 3157,
        search: ["pain"],
        nucc_specialties: ["208VP0014X", "208VP0000X", "2084P2900X", "207LP2900X"],
    },
    {
        name: "Pathology-No Surgery",
        value: 3158,
        search: [
            "pathology",
            "anatomic pathology",
            "derma",
            "neuropathology",
            "medical microbiology",
            "medical g",
            "clinical lab",
        ],
        nucc_specialties: [
            "207ZP0101X",
            "207ZC0008X",
            "207ZP0105X",
            "207ZC0500X",
            "207ZN0500X",
            "207ZF0201X",
            "207ND0900X",
            "207ZI0100X",
            "207ZM0300X",
            "207ZP0104X",
            "207ZP0102X",
            "207ZB0001X",
            "207ZP0213X",
            "207ZP0007X",
            "207SM0001X",
            "207ZD0900X",
            "207ZC0006X",
        ],
    },
    {
        name: "Pediatrics-Minor Surgery",
        value: 3160,
        search: ["pediatric pul", "pediatric surg", "obe", ""],
        nucc_specialties: [
            "2080A0000X",
            "2080B0002X",
            "2080P0006X",
            "208000000X",
            "2080T0004X",
            "2080C0008X",
            "2080P0214X",
            "2080H0002X",
            "2086S0120X",
        ],
    },
    {
        name: "Pediatrics-No Surgery",
        value: 3159,
        search: ["pediatrics", "medical", "pediatric card", "child", ""],
        nucc_specialties: [
            "2080P0006X",
            "2080A0000X",
            "2080T0002X",
            "2080C0008X",
            "208000000X",
            "2080H0002X",
        ],
    },
    {
        name: "Pharmacology Clinical",
        value: 3161,
        search: ["clinical pharm"],
        nucc_specialties: ["208U00000X"],
    },
    { name: "Phlebology", value: 3165, search: ["phleb"], nucc_specialties: ["202K00000X"] },
    {
        name: "Physiatry",
        value: 3162,
        search: ["physical medicine", "psychiatry", "neu", "neuro", "psychiatry & neurology", "pain"],
        nucc_specialties: [
            "2081P2900X",
            "2081P0301X",
            "204D00000X",
            "204C00000X",
            "2081S0010X",
            "2081N0008X",
            "2084N0600X",
            "2081P0004X",
            "208100000X",
            "2081H0002X",
            "2081P0010X",
            "2084N0008X",
        ],
    },
    {
        name: "Physicians NOC-No Surgery",
        value: 3163,
        search: ["med"],
        nucc_specialties: ["170100000X"],
    },
    { name: "Physicians NOC-Minor Surgery", value: 3164 }, // never produced in any model
    {
        name: "Plastic NOC-Surgery",
        value: 3166,
        search: ["plastic"],
        nucc_specialties: ["208200000X", "2086S0122X", "2082S0099X"],
    },
    {
        name: "Plastic Otorhinolaryngology-Surgery",
        value: 3167,
        search: ["plast", "ophthalmology", "otolaryngology"],
        nucc_specialties: ["207YX0007X", "207WX0200X", "207YS0123X"],
    },
    {
        name: "Psychiatry",
        value: 3168,
        search: ["psych", "addict", "child", "baria"],
        nucc_specialties: [
            "2084P0802X",
            "2084B0002X",
            "2084P0804X",
            "2084P0805X",
            "2084P0015X",
            "2084P0800X",
            "2084A0401X",
        ],
    },
    {
        name: "Pulmonary Diseases-No Surgery",
        value: 3169,
        search: ["pulm"],
        nucc_specialties: ["207RP1001X"],
    },
    {
        name: "Radiation Therapy NOC",
        value: 3170,
        search: ["rad", "therapeutic"],
        nucc_specialties: ["2085R0001X", "2085R0203X"],
    },
    {
        name: "Radiology Diagnostic-Minor Surgery",
        value: 3172,
        search: ["radio", "diagnostic", "vasc", "pediatric radiology", "neuro", "psych"],
        nucc_specialties: [
            "2085R0204X",
            "2085P0229X",
            "2085D0003X",
            "2085R0202X",
            "2085U0001X",
            "2085R0205X",
            "2085N0904X",
            "2085N0700X",
            "2084D0003X",
            "2085B0100X",
        ],
    },
    {
        name: "Radiology Diagnostic-No Surgery",
        value: 3171,
        search: ["radio", "diagnostic", "pediatric radiology", "neuro", "psych"],
        nucc_specialties: [
            "2085N0700X",
            "2085B0100X",
            "2085P0229X",
            "2085H0002X",
            "2085N0904X",
            "2085U0001X",
            "2084D0003X",
            "2085R0205X",
            "2085R0202X",
            "2085D0003X",
        ],
    },
    { name: "Radiology Incl Mammography", value: 3173 }, // never produced in any model
    {
        name: "Rheumatology-No Surgery",
        value: 3174,
        search: ["rheum", "pediatric rheum"],
        nucc_specialties: ["207RR0500X", "2080P0216X"],
    },
    { name: "Rhinology-No Surgery", value: 3175 }, // never produced in any model
    { name: "Rhinology-Minor Surgery", value: 3176 }, // never produced in any model
    { name: "Rhinology-Surgery", value: 3177 }, // never produced in any model
    { name: "Thoracic-Surgery", value: 3178, search: ["thor"], nucc_specialties: ["208G00000X"] },
    {
        name: "Traumatic-Surgery",
        value: 3179,
        search: ["surg", "trauma"],
        nucc_specialties: ["2086S0102X", "2086S0127X"],
    },
    { name: "Urgent Care-No Surgery", value: 3180 }, // only produced in model 64af127a4e00004a0057185a
    {
        name: "Urological-Surgery",
        value: 3181,
        search: ["uro"],
        nucc_specialties: ["208800000X", "2088P0231X"],
    },
    { name: "Vascular-Surgery", value: 3182, search: ["vasc"], nucc_specialties: ["2086S0129X"] },
];
export function getSpecialtyDetails(specialty) {
    const lookup = INDIGO_SPECIALTIES.filter((spec) => specialty === `${spec.name}`);
    if (lookup) {
        switch (lookup.length) {
            case 1:
                return lookup[0];
            case 0:
                throw new Error("no specialities match: " + specialty);
            default:
                throw new Error("multiple specialties detected: " + specialty);
        }
    }
    else {
        throw new Error("no specialities match" + specialty);
    }
}
export function getSpecialtyDetailsById(specialtyId) {
    const lookup = INDIGO_SPECIALTIES.filter((spec) => parseInt(specialtyId) === spec.value);
    if (lookup) {
        switch (lookup.length) {
            case 1:
                return lookup[0];
            case 0:
                throw new Error("no specialities match: " + specialtyId);
            default:
                throw new Error("multiple specialties detected: " + specialtyId);
        }
    }
    else {
        throw new Error("no specialities match" + specialtyId);
    }
}
