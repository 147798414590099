import { ReactNode, ChangeEvent } from "react";
import {
  DEFAULT_EXCLUDED_NPI_MRS,
  DEFAULT_MRS_VALUE,
} from "@app-stack/common/util/calculate_insights";
import {
  Spinner,
  Tooltip,
  FormLabel,
  Select,
  Box,
  Card,
  CardBody,
  Flex,
  Text,
  Icon,
} from "@chakra-ui/react";
import { ExpiredIcon } from "assets";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { DetailSummary, RangeDisplay, Datum, CardAction, Rolodex, SelectArrow } from "components";
import { InsightDetailType, Consistency, SuggestedAction } from "localTypes";
import { INDIGO_SPECIALTIES, PROVIDER_SUFFIXES } from "app-constants";
import { Maybe } from "__generated__/graphql";
import { useAppetite, useStalePricing, useIsPollingSearchResults } from "providers";
import { IoFlag } from "react-icons/io5";

export interface ProviderCardProps {
  name: string;
  mrs?: Maybe<number>;
  id?: string;
  mrsThreshold: number;
  boardActionsCount?: number;
  claimsCount?: number;
  redFlagsCount?: number;
  webSearchCount?: number;
  redFlagSearchCompleted?: Maybe<boolean>;
  consistency?: Consistency;
  county: string;
  suffix: string;
  specialty?: string;
  npi?: string;
  index?: number;
  lengthOfSet: number;
  adjustedMRS: number;
  adjustedPercent: number;
  handleChangeSpecialty: (e: ChangeEvent<HTMLSelectElement>) => void;
  bookedSRF: number;
  showInsightModal: (type: InsightDetailType) => void;
  customerId?: string;
  indigoPremium?: string;
  isLoading?: boolean;
  isLocked?: boolean;
  isNewToPractice?: boolean;
  isExcluded?: boolean;
}

export function ProviderCard({
  isLocked,
  isLoading,
  county,
  adjustedPercent,
  handleChangeSpecialty,
  name,
  id,
  npi,
  mrs,
  mrsThreshold,
  specialty,
  suffix,
  index,
  lengthOfSet,
  claimsCount,
  webSearchCount,
  redFlagsCount,
  redFlagSearchCompleted,
  boardActionsCount,
  consistency,
  adjustedMRS,
  bookedSRF,
  showInsightModal,
  customerId,
  indigoPremium,
  isNewToPractice,
  isExcluded,
}: ProviderCardProps) {
  const appetite = useAppetite();
  const { isPollingSearchEvents, pollingProviderIds } = useIsPollingSearchResults();
  const providerAppetite = appetite?.providers?.find((provider) => provider.id === id);
  const hasBoardActionFlag = providerAppetite?.messages.some(
    (reason) => reason.text === "Check Board Actions",
  );
  const claims = providerAppetite?.messages?.some((reason) => reason.text === "Check Claims");
  let backgroundColor = "gray.100";
  let rangeDisplayColorScheme = "gray";
  switch (providerAppetite?.action) {
    case SuggestedAction.Quote:
      backgroundColor = "green.50";
      rangeDisplayColorScheme = "green";
      break;
    case SuggestedAction.Decline:
      backgroundColor = "red.100";
      rangeDisplayColorScheme = "red";
      break;
    case SuggestedAction.Review:
      backgroundColor = "yellow.100";
      rangeDisplayColorScheme = "yellow";
      break;
    default:
      break;
  }
  let actionComponent: ReactNode | ReactNode[] = providerAppetite?.messages
    ?.filter((message) => {
      switch (providerAppetite.action) {
        case SuggestedAction.Quote:
          return message.intent === "success";
        case SuggestedAction.Decline:
          return message.intent === "error";
        case SuggestedAction.Review:
          return message.intent === "warning";
        default:
          return true;
      }
    })
    ?.map((message, index) => (
      <CardAction key={index} message={message.text} variant={message.intent} />
    ));
  if (providerAppetite?.action === SuggestedAction.Quote) {
    actionComponent = <CardAction message="Meets All Criteria." variant="success" />;
  }
  const hasStalePricing = useStalePricing();
  function customerIdent() {
    if (!customerId) {
      return (
        <Flex w="100%">
          <Datum label="" value="" />
        </Flex>
      );
    } else {
      return (
        <Flex w="100%">
          <Datum label="Simplify Customer ID" value={customerId} />
        </Flex>
      );
    }
  }

  function newProvider() {
    if (isNewToPractice) {
      return (
        <Flex w="100%" gap="8px">
          <Icon as={IoFlag} mt="5px" aria-label="new practice flag" color="red.500" />
          <Text fontSize="sm" fontWeight="normal" textAlign="left" lineHeight="xs">
            New to Practice
          </Text>
        </Flex>
      );
    }
  }

  return (
    <Card mb="12px">
      <CardBody p="0">
        <Flex>
          <Flex
            basis="45%"
            gap="12px"
            direction="column"
            p="24px"
            borderRight="1px"
            borderRightColor="gray.300"
            w="100%"
          >
            <Flex direction="column" mb="5px">
              <Text color="gray.450" fontWeight="normal" lineHeight="shorter">
                Individual Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontSize="3xl">
                {name}
                {suffix === "" ? "" : `, ${PROVIDER_SUFFIXES[suffix || ""]}`}
              </Text>
            </Flex>
            {newProvider()}
            <Flex w="100%" direction={{ lg: "row", base: "column" }}>
              <Datum label="County" value={county} />
              {npi && (
                <Box textAlign={{ lg: "right", base: "left" }}>
                  <Datum label="NPI Number" value={npi} />
                </Box>
              )}
            </Flex>
            {customerIdent()}
            <Datum
              label={
                <FormLabel fontWeight="normal" htmlFor="providerSpecialty">
                  Indigo Specialty
                </FormLabel>
              }
              value={
                <Flex>
                  <Select
                    isDisabled={isLoading}
                    icon={
                      isLoading ? (
                        <Spinner fontSize="1rem" color="indigo.500" />
                      ) : isLocked ? (
                        <span />
                      ) : (
                        <SelectArrow />
                      )
                    }
                    id="providerSpecialty"
                    size="md"
                    mt="6px"
                    p="0px"
                    borderColor={isLocked ? "gray.200" : "indigo.300"}
                    onChange={handleChangeSpecialty}
                    value={specialty}
                  >
                    {INDIGO_SPECIALTIES.map((spec) => (
                      <option key={spec.value} value={spec.name}>
                        {spec.name}
                      </option>
                    ))}
                  </Select>
                </Flex>
              }
            />
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems={{ lg: "center", base: "flex-start" }}
              direction={{ lg: "row", base: "column" }}
            >
              <Datum
                label="Credit/Debit to MRS"
                value={
                  <Flex>
                    <Rolodex num={adjustedPercent} />%
                  </Flex>
                }
              />
              <Box w="100%" textAlign={{ lg: "right", base: "left" }}>
                <Datum label="MRS" value={<Rolodex num={mrs ?? 0} />} />
              </Box>
            </Flex>
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems={{ lg: "center", base: "flex-start" }}
              direction={{ lg: "row", base: "column" }}
            >
              <Datum
                label="Booked SRF"
                value={
                  <Flex>
                    <Rolodex num={bookedSRF} />%
                  </Flex>
                }
              />
              <Box w="100%" textAlign={{ lg: "right", base: "start" }}>
                <Datum label="Average Adjusted MRS" value={<Rolodex num={adjustedMRS} />} />
              </Box>
            </Flex>
            <Flex w="100%" justifyContent="space-between" alignItems="center">
              {indigoPremium && (
                <Datum
                  label="Indigo Premium"
                  value={
                    <Flex>
                      {indigoPremium}
                      {hasStalePricing && (
                        <Tooltip hasArrow={true} label="Pricing data may be incorrect">
                          <Flex>
                            <ExpiredIcon />
                          </Flex>
                        </Tooltip>
                      )}
                    </Flex>
                  }
                />
              )}
            </Flex>
          </Flex>
          <Flex basis="55%" w="100%" justifyContent="space-between">
            <Flex flex="1" p="24px" direction="column" justifyContent="flex-start">
              <Flex
                as="button"
                onClick={() => showInsightModal(InsightDetailType.Confidence)}
                data-testid="provider-summary"
                padding="16px"
                backgroundColor={backgroundColor}
                overflow="hidden"
                justifyContent="space-between"
                gap={{ lg: "18px", base: "0px" }}
                direction={{ lg: "row", base: "column" }}
                alignItems="center"
                borderRadius="3px"
              >
                <Box textAlign="left" w="100%" flex={1}>
                  <Datum
                    label={
                      <Text color="black" fontWeight="bold">
                        MRS
                      </Text>
                    }
                    value={
                      <Rolodex
                        num={isExcluded ? DEFAULT_EXCLUDED_NPI_MRS : mrs ?? DEFAULT_MRS_VALUE}
                      />
                    }
                  />
                  <RangeDisplay
                    colorScheme={rangeDisplayColorScheme}
                    threshold={mrsThreshold}
                    value={isExcluded ? DEFAULT_EXCLUDED_NPI_MRS : mrs || DEFAULT_MRS_VALUE}
                    min={0}
                    max={2}
                  />
                </Box>
                <Flex
                  flex={1}
                  w="100%"
                  h="100%"
                  pl="20px"
                  alignItems="center"
                  justifyContent="center"
                  borderLeft={{ lg: "1px", base: "0px" }}
                  borderLeftColor={{ lg: "gray.300", base: "none" }}
                >
                  <Flex alignItems="center">
                    <Flex direction="column">{actionComponent}</Flex>
                    <ChevronRightIcon fontSize="1.5rem" color="gray.450" />
                  </Flex>
                </Flex>
              </Flex>
              <Flex direction="column" pt="12px">
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.BoardActions)}
                  label="Board Actions"
                  value={
                    boardActionsCount !== undefined
                      ? `${boardActionsCount} Action${boardActionsCount === 1 ? "" : "s"}`
                      : "Coming soon"
                  }
                  intent={
                    boardActionsCount === undefined
                      ? "neutral"
                      : hasBoardActionFlag
                        ? "warning"
                        : boardActionsCount > 0
                          ? "negative"
                          : "positive"
                  }
                />
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.Consistency)}
                  label="Provider Consistency"
                  value={consistency !== undefined ? `${consistency}` : "Unknown"}
                  intent={
                    consistency === undefined
                      ? "neutral"
                      : consistency === Consistency.High
                        ? "positive"
                        : "negative"
                  }
                />
                {/*MAKE RED TO GREEN IF NOT BREAKING RULE*/}
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.Claims)}
                  label="Claims"
                  value={claimsCount !== undefined ? `${claimsCount} Claims` : "Unknown"}
                  intent={
                    claimsCount === undefined
                      ? "neutral"
                      : claimsCount > 0 && claims
                        ? "negative"
                        : "positive"
                  }
                />
                <DetailSummary
                  isLoading={
                    !redFlagSearchCompleted ||
                    (isPollingSearchEvents && pollingProviderIds?.includes(id || ""))
                  }
                  loadingText="Search in progress"
                  handleClick={() => showInsightModal(InsightDetailType.WebSearch)}
                  label="Web Search"
                  value={webSearchCount !== undefined ? `${webSearchCount} Results` : "Unknown"}
                  intent={
                    webSearchCount === undefined
                      ? "neutral"
                      : webSearchCount > 0
                        ? "negative"
                        : "positive"
                  }
                />
                <DetailSummary
                  isLoading={
                    !redFlagSearchCompleted ||
                    (isPollingSearchEvents && pollingProviderIds?.includes(id || ""))
                  }
                  loadingText="Search in progress"
                  handleClick={() => showInsightModal(InsightDetailType.RedFlagSearch)}
                  label="Red Flags Search"
                  value={redFlagsCount !== undefined ? `${redFlagsCount} Results` : "Unknown"}
                  intent={
                    redFlagsCount === undefined
                      ? "neutral"
                      : redFlagsCount > 0
                        ? "negative"
                        : "positive"
                  }
                />
                <DetailSummary
                  handleClick={() => showInsightModal(InsightDetailType.NegativeFactors)}
                  label="Negative Factors"
                  value="Click to see more"
                  intent="warning"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}
