export var Intent;
(function (Intent) {
    Intent["Success"] = "success";
    Intent["Warning"] = "warning";
    Intent["Error"] = "error";
})(Intent || (Intent = {}));
export var SuggestedAction;
(function (SuggestedAction) {
    SuggestedAction["Quote"] = "quote";
    SuggestedAction["Review"] = "review";
    SuggestedAction["Decline"] = "decline";
})(SuggestedAction || (SuggestedAction = {}));
export var UserGroups;
(function (UserGroups) {
    UserGroups["Underwriters"] = "Underwriters";
    UserGroups["Brokers"] = "Brokers";
    UserGroups["Unknown"] = "Unknown";
})(UserGroups || (UserGroups = {}));
export var EntityCharges;
(function (EntityCharges) {
    EntityCharges["PRACTICE_ENTITIES_SEPARATE_LIMITS"] = "Practice entities, separate limits";
    EntityCharges["PRACTICE_ENTITIES_SHARED_LIMITS"] = "Practice entities, shared limits";
})(EntityCharges || (EntityCharges = {}));
export var Consistency;
(function (Consistency) {
    Consistency["High"] = "high";
    Consistency["Low"] = "low";
})(Consistency || (Consistency = {}));
export var InsightDetailType;
(function (InsightDetailType) {
    InsightDetailType["Claims"] = "Claims";
    InsightDetailType["Consistency"] = "Consistency";
    InsightDetailType["Confidence"] = "Confidence";
    InsightDetailType["BoardActions"] = "Board Actions";
    InsightDetailType["NegativeFactors"] = "Negative Factors";
    InsightDetailType["WebSearch"] = "Web Search";
    InsightDetailType["RedFlagSearch"] = "Red Flags";
})(InsightDetailType || (InsightDetailType = {}));
