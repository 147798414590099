import { useRef } from "react";
export const useScrollLock = () => {
    const isScrollingRef = useRef(false);
    const pauseScrolling = (duration) => {
        isScrollingRef.current = true;
        setTimeout(() => {
            isScrollingRef.current = false;
        }, duration);
    };
    return { isScrollingRef, pauseScrolling };
};
