import { z } from "zod";
export const NppesDbAddressSchema = z.object({
    firstLine: z.string().optional(),
    secondLine: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    postalCode: z.string().optional(),
    country: z.string().optional(),
    telephone: z.string().optional(),
    fax: z.string().optional(),
});
export const NppesDbTaxonomySchema = z.object({
    taxonomyCode: z.string().optional(),
    licenseNumber: z.string().optional(),
    licenseStateCode: z.string().optional(),
    primary: z.string().optional(),
    taxonomyGroup: z.string().optional(),
});
export const NppesDbOtherIdentifierSchema = z.object({
    identifier: z.string().optional(),
    identifierTypeCode: z.string().optional(),
    stateCode: z.string().optional(),
    issuer: z.string().optional(),
});
export const NppesDbRowSchema = z.object({
    npi: z.string(),
    entityTypeCode: z.string().optional(),
    replacementNpi: z.string().optional(),
    ein: z.string().optional(),
    organizationName: z.string().optional(),
    resolvedLastName: z.string(),
    resolvedFirstName: z.string(),
    resolvedOtherLastName: z.string(),
    resolvedOtherFirstName: z.string(),
    lastName: z.string().optional(),
    firstName: z.string().optional(),
    middleName: z.string().optional(),
    namePrefix: z.string().optional(),
    nameSuffix: z.string().optional(),
    credential: z.string().optional(),
    otherOrganizationName: z.string().optional(),
    otherOrganizationNameTypeCode: z.string().optional(),
    otherLastName: z.string().optional(),
    otherFirstName: z.string().optional(),
    otherMiddleName: z.string().optional(),
    otherNamePrefix: z.string().optional(),
    otherNameSuffix: z.string().optional(),
    otherCredential: z.string().optional(),
    otherLastNameTypeCode: z.string().optional(),
    mailingAddress: NppesDbAddressSchema.optional(),
    practiceAddress: NppesDbAddressSchema.optional(),
    providerEnumerationDate: z.string().optional(),
    lastUpdateDate: z.string().optional(),
    npiDeactivationReasonCode: z.string().optional(),
    npiDeactivationDate: z.string().optional(),
    npiReactivationDate: z.string().optional(),
    providerGenderCode: z.string().optional(),
    authorizedOfficialLastName: z.string().optional(),
    authorizedOfficialFirstName: z.string().optional(),
    authorizedOfficialMiddleName: z.string().optional(),
    authorizedOfficialTitle: z.string().optional(),
    authorizedOfficialTelephoneNumber: z.string().optional(),
    taxonomies: z.array(NppesDbTaxonomySchema).optional(),
    otherIdentifiers: z.array(NppesDbOtherIdentifierSchema).optional(),
    isSoleProprietor: z.string().optional(),
    isOrganizationSubpart: z.string().optional(),
    parentOrganizationLbn: z.string().optional(),
    parentOrganizationTin: z.string().optional(),
    authorizedOfficialNamePrefix: z.string().optional(),
    authorizedOfficialNameSuffix: z.string().optional(),
    authorizedOfficialCredential: z.string().optional(),
    certificationDate: z.string().optional(),
});
