import { useEffect, useState, ChangeEvent } from "react";
import {
  Box,
  Select,
  Button,
  Badge,
  Card,
  CardBody,
  Collapse,
  CardHeader,
  Grid,
  GridItem,
  Text,
  IconButton,
  Flex,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Controller,
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormClearErrors,
} from "react-hook-form";
import {
  WarningIcon,
  CopyIcon,
  DeleteIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";
import { INDIGO_NON_PHYSICIAN_SPECIALTIES, MIDLEVEL_SUFFIXES, STATES } from "app-constants";
import { AddressOption, AddressInputField, DatePicker, SelectArrow } from "components";
import {
  Limit,
  LimitType,
  InputMaybe,
  AddressInput,
  SubmissionUpdateInput,
  MidlevelUpdateInput,
} from "__generated__/graphql";
import { addressToString, formatLimit, formatErrors } from "utils";
import { GoTriangleDown } from "react-icons/go";

interface MidlevelFormProps {
  address?: InputMaybe<AddressInput>;
  setValue?: UseFormSetValue<SubmissionUpdateInput>;
  index: number;
  lengthOfSet: number;
  register: UseFormRegister<SubmissionUpdateInput>;
  errors?: FieldErrors<SubmissionUpdateInput>;
  onChangeSelect?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  control: Control<SubmissionUpdateInput>;
  deleteMidlevel: () => void;
  duplicateMidlevel: () => void;
  midlevel?: MidlevelUpdateInput;
  forwardRef?: (el: HTMLDivElement) => void;
  clearErrors: UseFormClearErrors<SubmissionUpdateInput>;
  isSelected?: boolean;
}

export function MidlevelForm({
  address,
  setValue,
  errors,
  index,
  lengthOfSet,
  register,
  onChangeSelect,
  control,
  deleteMidlevel,
  duplicateMidlevel,
  isSelected,
  midlevel,
  clearErrors,
  forwardRef: ref,
}: MidlevelFormProps) {
  const e = errors?.midlevels?.[index];
  const [selectedAddress, setSelectedAddress] = useState<AddressOption | null>(() => {
    if (address) {
      const { streetName, city, state, zip } = address;
      return {
        label: `${streetName || ""}, ${city || ""}, ${state || ""} ${zip || ""}`,
        value: address,
      };
    }
    return null;
  });
  const { onOpen, isOpen, onToggle } = useDisclosure({ defaultIsOpen: lengthOfSet <= 5 });

  useEffect(() => {
    if (!isOpen && isSelected) {
      onOpen();
    }
    // Disabling needing `isOpen` in the dependency array because
    // I do not want to force it to be open if it is closed locally
    // even if it is still technically selected.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, onOpen]);

  useEffect(() => {
    if (e) {
      onOpen();
    }
  }, [e, onOpen]);

  useEffect(() => {
    if (!midlevel?.firstName) {
      onOpen();
    }
  }, [onOpen, midlevel]);

  useEffect(() => {
    if (selectedAddress === null && address) {
      setSelectedAddress({
        label: addressToString(address),
        value: address,
      });
    }
  }, [selectedAddress, address, setSelectedAddress]);

  return (
    <div ref={ref} style={{ paddingTop: "16px" }}>
      <Card
        as={Button}
        onClick={onToggle}
        w="100%"
        aria-label="expand provider review card"
        variant="ghost"
        alignItems="start"
        h="80px"
        m="0px"
        p="20px"
        style={{
          boxShadow:
            "1px 1px 3px 0px rgba(0, 0, 0, 0.10), 1px 1px 3px 0px rgba(0, 0, 0, 0.10) !important",
          display: isOpen ? "none" : "flex",
        }}
        _hover={{ background: "white" }}
      >
        <CardHeader p="0" w="100%">
          <Flex p="0" w="100%" justifyContent="space-between" alignItems="center">
            <Flex direction="column" textAlign="left">
              <Text fontSize="xm" color="gray.400" fontWeight="normal">
                Non-Physician Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `- ${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontWeight="700" fontSize="md" p="0">
                {`${midlevel?.firstName || ""} ${midlevel?.lastName || ""}`}{" "}
              </Text>
            </Flex>
            <Flex gap="16px">
              {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
              <Box w="40px" h="40px" pt="6px" pl="2px">
                <ChevronDownIcon fontSize="1.5rem" />
              </Box>
            </Flex>
          </Flex>
        </CardHeader>
      </Card>
      <Card
        style={{ display: isOpen ? "block" : "none" }}
        bgColor="white"
        borderRadius="3px"
        variant="outline"
        pb="15px"
        mt="16px"
      >
        <CardHeader pb="0">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              direction="column"
              textAlign="left"
              alignItems="flex-start"
              _hover={{ background: "transparent" }}
              backgroundColor="transparent"
              w="100%"
              as={Button}
              p="0"
              m="0"
              onClick={onToggle}
            >
              <Text fontSize="xm" color="gray.400" fontWeight="normal">
                Non-Physician Provider{" "}
                {index !== undefined && lengthOfSet > 1 ? `- ${index + 1}/${lengthOfSet}` : ""}
              </Text>
              <Text lineHeight="none" fontWeight="700" fontSize="md" p="0">
                {`${midlevel?.firstName || ""} ${midlevel?.lastName || ""}`}{" "}
              </Text>
            </Flex>
            <Flex gap="16px">
              {e && <Badge variant="boxy">{formatErrors(e)}</Badge>}
              {isOpen && (
                <Button
                  onClick={deleteMidlevel}
                  variant="solid"
                  bgColor="indigo.100"
                  color="indigo.500"
                  aria-label="Delete midlevel"
                  m="0px"
                  rightIcon={<DeleteIcon fontSize="0.8rem" />}
                >
                  Delete
                </Button>
              )}
              {isOpen && (
                <Button
                  onClick={duplicateMidlevel}
                  variant="solid"
                  bgColor="indigo.100"
                  color="indigo.500"
                  aria-label="Copy midlevel"
                  m="0px"
                  rightIcon={<CopyIcon />}
                >
                  Duplicate
                </Button>
              )}
              {!isOpen ? (
                <IconButton
                  onClick={onToggle}
                  variant="ghost"
                  aria-label="Expand non-physician provider"
                  fontSize="1.5rem"
                  m="0px"
                  icon={<ChevronDownIcon />}
                />
              ) : (
                <IconButton
                  onClick={onToggle}
                  variant="ghost"
                  aria-label="Collapse non-physician provider"
                  fontSize="1.5rem"
                  m="0px"
                  icon={<ChevronUpIcon />}
                />
              )}
            </Flex>
          </Flex>
        </CardHeader>
        <Collapse in={isOpen} animateOpacity style={{ overflow: "visible" }}>
          {isOpen && (
            <CardBody pt="15px" pb="0px">
              <Grid
                templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
                gap="12px"
              >
                <GridItem colSpan={4}>
                  <FormControl isInvalid={!!e?.firstName?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.firstName`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                    >
                      First Name *
                    </FormLabel>
                    <Input
                      id={`midlevels.${index}.firstName`}
                      type="text"
                      {...register(`midlevels.${index}.firstName`, {
                        required: "First name is required",
                      })}
                    />
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.firstName?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl isInvalid={!!e?.lastName?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.lastName`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                    >
                      Last Name *
                    </FormLabel>
                    <Input
                      id={`midlevels.${index}.lastName`}
                      type="text"
                      {...register(`midlevels.${index || 0}.lastName`, {
                        required: "Last name is required",
                      })}
                    />
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.lastName?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel
                      htmlFor={`midlevels.${index}.suffix`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                    >
                      Suffix
                    </FormLabel>
                    <Select
                      id={`midlevels.${index}.suffix`}
                      size="md"
                      icon={<SelectArrow />}
                      {...register(`midlevels.${index}.suffix`)}
                      onChange={onChangeSelect || register(`midlevels.${index}.suffix`).onChange}
                    >
                      <option></option>
                      {Object.keys(MIDLEVEL_SUFFIXES).map((spec) => (
                        <option key={spec} value={spec}>
                          {MIDLEVEL_SUFFIXES[spec]}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <Controller
                    rules={{ required: "Retro date is required" }}
                    render={({ fieldState, field }) => (
                      <FormControl isInvalid={Boolean(fieldState.error)}>
                        <FormLabel
                          htmlFor={`midlevels.${index}.retroDate`}
                          fontSize="md"
                          mb="0px"
                          fontWeight="normal"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          Retro Date *
                        </FormLabel>
                        <DatePicker
                          id={`midlevels.${index}.retroDate`}
                          invalid={Boolean(fieldState.error)}
                          selected={field?.value || ""}
                          onChange={(date: string) => {
                            clearErrors(`midlevels.${index}.retroDate`);
                            setValue?.(`midlevels.${index}.retroDate`, date);
                          }}
                        />
                        <FormErrorMessage>
                          <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                          {fieldState.error && fieldState.error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                    control={control}
                    name={`midlevels.${index}.retroDate`}
                  />
                </GridItem>
              </Grid>
              <Grid
                pt="12px"
                templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
                gap="12px"
              >
                <GridItem colSpan={2}>
                  <FormControl isInvalid={!!e?.limit?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.limit`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Limit *
                    </FormLabel>
                    <Select
                      id={`midlevels.${index}.limit`}
                      size="md"
                      icon={<SelectArrow />}
                      {...register(`midlevels.${index}.limit`, {
                        required: "Limit is required",
                      })}
                      onChange={(e) => {
                        clearErrors(`midlevels.${index}.limit`);
                        onChangeSelect?.(e) || register(`midlevels.${index}.limit`).onChange(e);
                      }}
                    >
                      {Object.values(Limit).map((limit) => {
                        return (
                          <option key={limit} value={limit}>
                            {formatLimit(limit)}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.limit?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl isInvalid={!!e?.limitType?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.limitType`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Limit Type *
                    </FormLabel>
                    <Select
                      id={`midlevels.${index}.limitType`}
                      size="md"
                      icon={<SelectArrow />}
                      {...register(`midlevels.${index}.limitType`, {
                        required: "Limit type is required",
                      })}
                      onChange={(e) => {
                        clearErrors(`midlevels.${index}.limitType`);
                        onChangeSelect?.(e) || register(`midlevels.${index}.limitType`).onChange(e);
                      }}
                    >
                      {Object.values(LimitType).map((limitType) => {
                        return (
                          <option key={limitType} value={limitType}>
                            {limitType}
                          </option>
                        );
                      })}
                    </Select>
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.limitType?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl>
                    <FormLabel
                      htmlFor={`midlevels.${index}.specialty`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                    >
                      Broker Specialty
                    </FormLabel>
                    <Input
                      id={`midlevels.${index}.specialty`}
                      type="text"
                      {...register(`midlevels.${index}.specialty`)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl isInvalid={!!e?.indigoSpecialty?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.indigoSpecialty`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                    >
                      Indigo Specialty *
                    </FormLabel>
                    <Select
                      id={`midlevels.${index}.indigoSpecialty`}
                      size="md"
                      icon={<SelectArrow />}
                      {...register(`midlevels.${index}.indigoSpecialty`, {
                        required: "Specialty is required",
                      })}
                      onChange={(e) => {
                        clearErrors(`midlevels.${index}.indigoSpecialty`);
                        onChangeSelect?.(e) ||
                          register(`midlevels.${index}.indigoSpecialty`).onChange(e);
                      }}
                    >
                      <option></option>
                      {INDIGO_NON_PHYSICIAN_SPECIALTIES.map((spec) => (
                        <option key={`${spec.value}-${spec.name}`} value={spec.name}>
                          {spec.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.indigoSpecialty?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
              <Grid
                gap="12px"
                templateColumns={{ base: "1fr", md: "repeat(1, 1fr)", lg: "repeat(12, 1fr)" }}
              >
                <GridItem colSpan={3}>
                  <Controller
                    rules={{ required: "Address is required" }}
                    render={({ fieldState }) => (
                      <FormControl isInvalid={Boolean(fieldState.error)}>
                        <FormLabel
                          htmlFor={`midlevels.${index}.address.streetName`}
                          fontSize="md"
                          mb="0px"
                          fontWeight="normal"
                        >
                          Address *
                        </FormLabel>
                        <AddressInputField
                          onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                            clearErrors(`midlevels.${index}.address`);
                            setValue?.(
                              `midlevels.${index}.address.streetName`,
                              e?.target?.value || "",
                            );
                          }}
                          isInvalid={Boolean(fieldState.error)}
                          id={`midlevels.${index}.address.streetName`}
                          onChange={(option: AddressOption | null) => {
                            clearErrors(`midlevels.${index}.address`);
                            setSelectedAddress(option);
                            setValue?.(`midlevels.${index}.address`, option?.value || null);
                            setValue?.(
                              `midlevels.${index}.address.city`,
                              option?.value?.city || "",
                            );
                            setValue?.(
                              `midlevels.${index}.address.state`,
                              option?.value?.state || "",
                            );
                            setValue?.(`midlevels.${index}.address.zip`, option?.value?.zip || "");
                            setValue?.(
                              `midlevels.${index}.address.secondary`,
                              option?.value?.secondary || "",
                            );
                            setValue?.(
                              `midlevels.${index}.address.streetName`,
                              option?.value?.streetName || "",
                            );
                          }}
                          value={selectedAddress}
                        />
                        <FormErrorMessage>
                          <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                          {fieldState.error && fieldState.error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                    control={control}
                    name={`midlevels.${index}.address.streetName`}
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel
                      htmlFor={`midlevels.${index}.address.secondary`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Address Line 2
                    </FormLabel>
                    <Input
                      id={`midlevels.${index}.address.secondary`}
                      type="text"
                      {...register(`midlevels.${index}.address.secondary`)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <Controller
                    name={`midlevels.${index}.address.city`}
                    rules={{ required: "City is required" }}
                    control={control}
                    render={({ field, fieldState }) => (
                      <FormControl isInvalid={!!(fieldState.error && fieldState.error.message)}>
                        <FormLabel
                          htmlFor={`midlevels.${index}.address.city`}
                          fontSize="md"
                          mb="0px"
                          fontWeight="normal"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          City *
                        </FormLabel>
                        <Input
                          id={`midlevels.${index}.address.city`}
                          type="text"
                          {...field}
                          value={field?.value || ""}
                        />
                        <FormErrorMessage>
                          <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                          {fieldState.error && fieldState.error.message}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isInvalid={!!e?.address?.state?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.address.state`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      State *
                    </FormLabel>
                    <Select
                      id={`midlevels.${index}.address.state`}
                      size="md"
                      icon={<GoTriangleDown />}
                      {...register(`midlevels.${index}.address.state`, {
                        required: "State is required",
                      })}
                      onChange={
                        onChangeSelect || register(`midlevels.${index}.address.state`).onChange
                      }
                    >
                      <option></option>
                      {STATES.map((state) => (
                        <option key={state.abbreviation} value={state.abbreviation}>
                          {state.abbreviation}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.address?.state?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={3}>
                  <FormControl isInvalid={!!e?.address?.zip?.message}>
                    <FormLabel
                      htmlFor={`midlevels.${index}.address.zip`}
                      fontSize="md"
                      mb="0px"
                      fontWeight="normal"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Zip Code *
                    </FormLabel>
                    <Input
                      id={`midlevels.${index}.address.zip`}
                      type="text"
                      {...register(`midlevels.${index}.address.zip`, {
                        required: "Zip code is required",
                      })}
                    />
                    <FormErrorMessage>
                      <WarningIcon fontSize="xxs" mr="3.5px" mt="3.5px" color="red.500" />
                      {e?.address?.zip?.message}
                    </FormErrorMessage>
                  </FormControl>
                </GridItem>
              </Grid>
            </CardBody>
          )}
        </Collapse>
      </Card>
    </div>
  );
}
