import { gql } from "@apollo/client";
export const CALCULATE_INSIGHTS_DATA = gql `
  mutation CalculateInsightsData($submissionId: ID!) {
    calculateInsightsData(submissionId: $submissionId) {
      averageMrs
      averageAdjustedMrs
      bookedSrf
      calculatedSrfAdjustment
      scheduledRatingFactor
      providers {
        srfAdjustment
        localAdjustedPercent
      }
    }
  }
`;
export const SAVE_SUBMISSION = gql `
  mutation PutSubmission($input: SubmissionInput!) {
    putSubmission(input: $input)
  }
`;
export const ADD_DECLINATION = gql `
  mutation AddDeclination($submissionId: ID!, $declination: DeclinationInput!) {
    addDeclination(submissionId: $submissionId, declination: $declination)
  }
`;
export const UPDATE_PRODUCER = gql `
  mutation UpdateProducer($submissionId: ID!, $producer: ProducerUpdateInput!) {
    updateProducer(submissionId: $submissionId, producer: $producer)
  }
`;
export const UPDATE_QUOTE_PROPERTIES = gql `
  mutation UpdateQuoteProperties(
    $submissionId: ID!
    $quoteProperties: QuotePropertiesUpdateInput!
  ) {
    updateQuoteProperties(submissionId: $submissionId, quoteProperties: $quoteProperties)
  }
`;
export const UPDATE_MIDLEVELS = gql `
  mutation UpdateMidlevels($submissionId: ID!, $midlevels: [MidlevelUpdateInput!]) {
    updateMidlevels(submissionId: $submissionId, midlevels: $midlevels)
  }
`;
export const UPDATE_ENTITIES = gql `
  mutation UpdateEntities($submissionId: ID!, $entities: [EntityUpdateInput!]) {
    updateEntities(submissionId: $submissionId, entities: $entities)
  }
`;
export const UPDATE_PROVIDERS = gql `
  mutation UpdateProviders($submissionId: ID!, $providers: [ProviderUpdateInput!]!) {
    updateProviders(submissionId: $submissionId, providers: $providers)
  }
`;
export const CREATE_NOTE = gql `
  mutation CreateNote($submissionId: ID!, $note: NoteInput!) {
    createNote(submissionId: $submissionId, note: $note)
  }
`;
export const CREATE_MIDLEVEL = gql `
  mutation CreateMidlevel($submissionId: ID!, $midlevel: MidlevelInput!) {
    createMidlevel(submissionId: $submissionId, midlevel: $midlevel)
  }
`;
export const CREATE_ENTITY = gql `
  mutation CreateEntity($submissionId: ID!, $entity: EntityInput!) {
    createEntity(submissionId: $submissionId, entity: $entity)
  }
`;
export const CREATE_PROVIDER = gql `
  mutation CreateProvider($submissionId: ID!, $provider: ProviderInput!) {
    createProvider(submissionId: $submissionId, provider: $provider)
  }
`;
export const UPDATE_SRF_ADJUSTMENT = gql `
  mutation UpdateSrfAdjustment(
    $submissionId: ID!
    $useCalculatedSrfAdjustment: Boolean
    $srfAdjustment: Float
    $calculatedSrfAdjustment: Float
  ) {
    updateSrfAdjustment(
      submissionId: $submissionId
      useCalculatedSrfAdjustment: $useCalculatedSrfAdjustment
      srfAdjustment: $srfAdjustment
      calculatedSrfAdjustment: $calculatedSrfAdjustment
    ) {
      srfAdjustment
      useCalculatedSrfAdjustment
    }
  }
`;
export const RESET_QUICK_AUDIT = gql `
  mutation ResetQuickAudit($submissionId: ID!) {
    resetQuickAudit(submissionId: $submissionId)
  }
`;
export const TOGGLE_IGNORE_LOCATION_INCONSISTENCY = gql `
  mutation ToggleIgnoreLocationInconsistency($submissionId: ID!, $providerId: ID!) {
    toggleIgnoreLocationInconsistency(submissionId: $submissionId, providerId: $providerId)
  }
`;
export const UPDATE_SUBMISSION = gql `
  mutation UpdateSubmission($input: SubmissionUpdateInput!) {
    updateSubmission(input: $input)
  }
`;
export const DELETE_PROVIDER = gql `
  mutation DeleteProvider($input: DeleteProviderInput!) {
    deleteProvider(input: $input)
  }
`;
export const DELETE_ENTITY = gql `
  mutation DeleteEntity($input: DeleteEntityInput!) {
    deleteEntity(input: $input)
  }
`;
export const DELETE_MIDLEVEL = gql `
  mutation DeleteMidlevel($input: DeleteMidlevelInput!) {
    deleteMidlevel(input: $input)
  }
`;
export const DELETE_NOTE = gql `
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input)
  }
`;
