var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useContext, useState } from "react";
import { ApiClientContext } from "providers";
import { RiskLevel } from "@app-stack/types/risk_color";
export function useRiskColor() {
    const [isLoading, setIsLoading] = useState(false);
    const [riskColor, setRiskColor] = useState(RiskLevel.UNKNOWN);
    const apiClient = useContext(ApiClientContext);
    const fetchRiskColor = useCallback((submission) => __awaiter(this, void 0, void 0, function* () {
        try {
            setIsLoading(true);
            const responseData = yield (apiClient === null || apiClient === void 0 ? void 0 : apiClient.riskColor(submission));
            setRiskColor((responseData === null || responseData === void 0 ? void 0 : responseData.riskLevel) || RiskLevel.UNKNOWN);
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsLoading(false);
        }
    }), [apiClient]);
    return { isLoading, riskColor, fetchRiskColor };
}
