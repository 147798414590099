import { z } from "zod";
export const nppesApiParamsCommon = {
    version: 2.1,
    limit: 200, // Maximum amount allowed by the NPI Registry
};
export function nppesApiParamsTypeGuard(params) {
    return Object.assign(Object.assign({}, nppesApiParamsCommon), params);
}
export const getNppesApiResultItemFirstName = (item) => {
    return "first_name" in item.basic
        ? item.basic.first_name
        : item.basic.authorized_official_first_name;
};
export const getNppesDbResultItemFirstName = (item) => {
    return item.resolvedFirstName === "UNKNOWN"
        ? item.resolvedOtherFirstName
        : item.resolvedFirstName;
};
export const getNppesApiResultItemLastName = (item) => {
    return "first_name" in item.basic
        ? item.basic.last_name
        : item.basic.authorized_official_last_name;
};
export const getNppesDbResultItemLastName = (item) => {
    return item.resolvedLastName === "UNKNOWN" ? item.resolvedOtherLastName : item.resolvedLastName;
};
export const NppesRequestBaseSchema = z.object({
    limit: z.number().optional(),
    skip: z.number().optional(),
});
export const NppesNumberSearchRequestSchema = NppesRequestBaseSchema.extend({
    number: z.string().min(1),
});
export const NppesNameSearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
});
export const NppesNameAndStateSearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    state: z.string().min(1),
});
export const NppesNameAndStateAndTaxonomySearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    state: z.string().min(1),
    taxonomy_description: z.array(z.string().min(1)),
});
export const NppesNameAndTaxonomySearchRequestSchema = NppesRequestBaseSchema.extend({
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    taxonomy_description: z.array(z.string().min(1)),
});
export const NppesSearchRequestSchema = z.union([
    NppesNumberSearchRequestSchema,
    NppesNameSearchRequestSchema,
    NppesNameAndStateSearchRequestSchema,
    NppesNameAndStateAndTaxonomySearchRequestSchema,
    NppesNameAndTaxonomySearchRequestSchema,
]);
