var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ApiClientContext } from "providers";
import { useMutation } from "@apollo/client";
import { CALCULATE_INSIGHTS_DATA, UPDATE_PROVIDERS } from "mutations";
import { GET_SUBMISSION } from "queries";
import { useToggleQuickAudit } from "hooks";
import { INDIGO_SPECIALTIES, DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE, DEFAULT_MRS_VALUE, } from "app-constants";
import { formatSpecialtyInconsistencies, formatLocationInconsistencies, formatBillingData, formatMrsTrends, convertClaimDateToClaim, generateFactors, } from "utils";
export function useModelRatingApi() {
    const { id } = useParams();
    const { isQuickAuditEnabled } = useToggleQuickAudit(id || "");
    const [updateProviders] = useMutation(UPDATE_PROVIDERS, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    });
    const [calculateInsightsData] = useMutation(CALCULATE_INSIGHTS_DATA, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    });
    const [loading, setLoading] = useState(false);
    const [modelData, setModelData] = useState();
    const apiClient = useContext(ApiClientContext);
    const fetchModelRating = useCallback((provider, submissionId, localIsQuickAuditEnabled) => __awaiter(this, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        try {
            setLoading(true);
            const insights = yield calculateInsightsData({
                variables: {
                    submissionId: id,
                },
            });
            const validIsQuickAuditEnabled = localIsQuickAuditEnabled !== undefined ? localIsQuickAuditEnabled : isQuickAuditEnabled;
            const validSpecialty = validIsQuickAuditEnabled
                ? provider === null || provider === void 0 ? void 0 : provider.indigoSpecialty
                : (provider === null || provider === void 0 ? void 0 : provider.manualIndigoSpecialty) || (provider === null || provider === void 0 ? void 0 : provider.indigoSpecialty);
            if (((_a = provider === null || provider === void 0 ? void 0 : provider.address) === null || _a === void 0 ? void 0 : _a.countyFips) && provider.npi && validSpecialty) {
                const modelRating = yield (apiClient === null || apiClient === void 0 ? void 0 : apiClient.modelRating({
                    countyFIPS: provider.address.countyFips,
                    npi: provider.npi,
                    specialty: ((_b = INDIGO_SPECIALTIES.find((specialty) => specialty.name === validSpecialty)) === null || _b === void 0 ? void 0 : _b.name) || "",
                }));
                setModelData(modelRating);
                const inconsistencies = {
                    location: formatLocationInconsistencies(provider, modelRating),
                    specialty: formatSpecialtyInconsistencies(provider, modelRating, isQuickAuditEnabled),
                };
                let newMRS;
                const validMrsKey = validIsQuickAuditEnabled ? "mrs" : "manualMrs";
                if ((_d = (_c = modelRating === null || modelRating === void 0 ? void 0 : modelRating.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.MODEL_RISK_SCORE) {
                    newMRS = parseFloat(modelRating.results[0].MODEL_RISK_SCORE.toFixed(2));
                }
                if ((provider === null || provider === void 0 ? void 0 : provider.ignoreLocationInconsistency) === false) {
                    newMRS = DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE;
                }
                if ((provider === null || provider === void 0 ? void 0 : provider.newToPractice) === 1) {
                    newMRS = DEFAULT_MRS_VALUE;
                }
                const claims = JSON.parse(((_f = (_e = modelRating === null || modelRating === void 0 ? void 0 : modelRating.results) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.PRIOR_LEGAL_CLAIM_REPORTED_DATES_REPORT) || "[]").map((claim) => convertClaimDateToClaim(claim, provider.npi || ""));
                if (provider[validMrsKey] !== newMRS ||
                    ((_g = provider === null || provider === void 0 ? void 0 : provider.claims) === null || _g === void 0 ? void 0 : _g.length) !== claims.length ||
                    !(provider === null || provider === void 0 ? void 0 : provider.billingData) ||
                    !(provider === null || provider === void 0 ? void 0 : provider.negativeFactors) ||
                    ((provider === null || provider === void 0 ? void 0 : provider.newToPractice) === 1 &&
                        provider[validMrsKey] !== ((_h = insights === null || insights === void 0 ? void 0 : insights.data.calculateInsightsData) === null || _h === void 0 ? void 0 : _h.averageMrs))) {
                    yield updateProviders({
                        variables: {
                            submissionId: id === undefined ? submissionId : id,
                            providers: [
                                {
                                    id: provider.id,
                                    [validMrsKey]: (provider === null || provider === void 0 ? void 0 : provider.newToPractice) === 1
                                        ? (_j = insights === null || insights === void 0 ? void 0 : insights.data.calculateInsightsData) === null || _j === void 0 ? void 0 : _j.averageMrs
                                        : newMRS,
                                    mrsTrends: formatMrsTrends(modelRating),
                                    billingData: formatBillingData(modelRating),
                                    negativeFactors: generateFactors(modelRating),
                                    inconsistencies,
                                    claims,
                                },
                            ],
                        },
                    });
                }
            }
            else {
                if (!provider.billingData) {
                    yield updateProviders({
                        variables: {
                            submissionId: id === undefined ? submissionId : id,
                            providers: [
                                {
                                    id: provider.id,
                                    billingData: formatBillingData(),
                                },
                            ],
                        },
                    });
                }
            }
            return modelData;
        }
        catch (e) {
            console.error("Error fetching model rating", e);
        }
        finally {
            setLoading(false);
        }
    }), [calculateInsightsData, modelData, id, updateProviders, apiClient, isQuickAuditEnabled]);
    return { loading, modelData, fetchModelRating };
}
