var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ApiClientContext } from "providers";
import { INDIGO_SPECIALTIES, DEFAULT_MRS_VALUE } from "app-constants";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION, UPDATE_PROVIDERS, UPDATE_MIDLEVELS } from "mutations";
import { sumPremiums, formatCustomerPremiums } from "utils";
import { GET_SUBMISSION } from "queries";
export const useFetchPricingData = () => {
    const [updateProviders] = useMutation(UPDATE_PROVIDERS);
    const [updateMidlevels] = useMutation(UPDATE_MIDLEVELS);
    const [updateSubmission] = useMutation(UPDATE_SUBMISSION, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    });
    const { id } = useParams();
    const [pricingErrors, setPricingErrors] = useState([]);
    const [isFetchingPricingData, setIsFetchingPricingData] = useState(false);
    const apiClient = useContext(ApiClientContext);
    const fetchPricingData = useCallback((submission, averageMrs) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d, _e;
        try {
            setPricingErrors([]);
            setIsFetchingPricingData(true);
            if (submission.providers) {
                const submittedProviders = (_a = submission.providers) === null || _a === void 0 ? void 0 : _a.map((provider) => {
                    var _a, _b;
                    let specialtyId;
                    const validSpecialty = submission.isQuickAuditEnabled
                        ? provider.indigoSpecialty
                        : provider.manualIndigoSpecialty;
                    if (validSpecialty) {
                        specialtyId = (_a = INDIGO_SPECIALTIES.find((specialty) => specialty.name === validSpecialty)) === null || _a === void 0 ? void 0 : _a.value;
                    }
                    else if (provider.indigoSpecialty) {
                        specialtyId = (_b = INDIGO_SPECIALTIES.find((specialty) => specialty.name === provider.indigoSpecialty)) === null || _b === void 0 ? void 0 : _b.value;
                    }
                    return Object.assign(Object.assign({}, provider), { indigoSpecialty: specialtyId, specialty: provider.specialty || validSpecialty, newToPractice: undefined, partTimePractice: provider.partTimePractice === "3102"
                            ? (averageMrs || 0) > 0.52
                                ? "3102"
                                : undefined
                            : undefined, mrs: provider.mrs || DEFAULT_MRS_VALUE });
                });
                let submittedMidlevels = (_b = submission.midlevels) === null || _b === void 0 ? void 0 : _b.map((midlevel) => {
                    return Object.assign(Object.assign({}, midlevel), { partTimePractice: midlevel.partTimePractice === "3102"
                            ? (averageMrs || 0) > 0.52
                                ? "3102"
                                : undefined
                            : undefined });
                });
                const pricingData = yield (apiClient === null || apiClient === void 0 ? void 0 : apiClient.invokeCalculateSunlightPremium(Object.assign(Object.assign({}, submission), { providers: submittedProviders, midlevels: submittedMidlevels || [], 
                    // Hardcode Indigo internal producer and agency IDs for price-check
                    producer: Object.assign(Object.assign({}, submission.producer), { producerId: "PR39775", agency: Object.assign(Object.assign({}, (_c = submission === null || submission === void 0 ? void 0 : submission.producer) === null || _c === void 0 ? void 0 : _c.agency), { agencyId: "BR57958" }) }), srfAdjustment: submission.useCalculatedSrfAdjustment
                        ? submission.calculatedSrfAdjustment
                        : submission.srfAdjustment })));
                if (pricingData) {
                    if (((_d = pricingData.quoteResults[0]) === null || _d === void 0 ? void 0 : _d.ListOfErrors) && submission.status !== "Declined") {
                        setPricingErrors(pricingData.quoteResults[0].ListOfErrors);
                    }
                    const validPreviewPremium = submission.isQuickAuditEnabled !== false ? "previewPremium" : "manualPreviewPremium";
                    const validPreviewPremiumsAreStale = submission.isQuickAuditEnabled !== false
                        ? "previewPremiumsAreStale"
                        : "manualPreviewPremiumsAreStale";
                    yield updateProviders({
                        variables: {
                            submissionId: id,
                            providers: submission.providers.map((provider) => {
                                var _a;
                                return {
                                    id: provider.id,
                                    [validPreviewPremium]: (_a = formatCustomerPremiums(pricingData).find((p) => p.npi === provider.npi)) === null || _a === void 0 ? void 0 : _a.premium,
                                };
                            }),
                        },
                    });
                    if (Array.isArray(submission.midlevels) && submission.midlevels.length > 0) {
                        yield updateMidlevels({
                            variables: {
                                submissionId: id,
                                midlevels: (_e = submission === null || submission === void 0 ? void 0 : submission.midlevels) === null || _e === void 0 ? void 0 : _e.map((midlevel) => {
                                    var _a;
                                    return Object.assign(Object.assign({}, midlevel), { [validPreviewPremium]: (_a = formatCustomerPremiums(pricingData).find((p) => `${p.firstName} ${p.lastName}` ===
                                            `${midlevel.firstName} ${midlevel.lastName}`)) === null || _a === void 0 ? void 0 : _a.premium });
                                }),
                            },
                        });
                    }
                    yield updateSubmission({
                        variables: {
                            input: {
                                id,
                                [validPreviewPremium]: sumPremiums(pricingData === null || pricingData === void 0 ? void 0 : pricingData.quoteResults),
                                [validPreviewPremiumsAreStale]: false,
                            },
                        },
                    });
                }
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setIsFetchingPricingData(false);
        }
    }), [apiClient, id, updateProviders, updateSubmission, updateMidlevels]);
    return { fetchPricingData, isFetchingPricingData, pricingErrors };
};
